<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <div class="card-header mt--4">
          <div class="row">
            <div class="col">
              <h3 class="mb-0 text-center">Process By Cataloguer</h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <h3>MCR Information</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table class="table table-responsive-md table-bordered">
                <tbody>
                  <tr>
                    <th class="bg-secondary">{{ tt('subject') }}</th>
                    <td colspan="3">{{ mcrInformation.subject }}</td>
                  </tr>
                  <tr>
                    <th class="bg-secondary">{{ tt('type') }}</th>
                    <td>{{ mcrInformation.form_description }}</td>
                    <th class="bg-secondary">{{ tt('created_by') }}</th>
                    <td>{{ mcrInformation.created_by }}</td>
                  </tr>
                  <tr>
                    <th class="bg-secondary">{{ tt('status') }}</th>
                    <td>{{ mcrInformation.status }}</td>
                    <th class="bg-secondary">{{ tt('created_at') }}</th>
                    <td>{{ mcrInformation.created_at }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col">
              <h3>MCR Items</h3>
            </div>
          </div>

          <div>
            <tabs tabNavClasses="nav-fill flex-column flex-sm-row">
              <tab-pane title="User Request">

                  <div class="card">
                      <div class="card-body">
                          <div class="row">
                              <div class="col-md-6">
                                  <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
                                  <base-input :name="tt('material_number')" rules="required">
                                      <input :disabled="form.a3.disabled" class="form-control form-control-sm" v-model="mcrItem.material_number">
                                      <!-- <el-select :disabled="form.a3.disabled" class="select-danger" v-model="inputDraftListA3.material_number" :placeholder="tt('choose_material_number')">
                                          <el-option class="select-danger" :value="mn.material_number" :label="mn.material_number" :key="mn.material_number" v-for="mn in  materialNumber"></el-option>
                                      </el-select> -->
                                  </base-input>
                              </div>
                              <div class="col-md-6">
                                  <label class="form-control-label">{{ tt('plant') }} <span class="text-danger">*</span></label>
                                  <base-input :name="tt('plant')" rules="required">
                                       <input :disabled="form.a3.disabled" class="form-control form-control-sm" v-model="mcrItem.plant_description">
                                      <!-- <el-select :disabled="form.a3.disabled" class="select-danger" v-model="inputDraftListA3.plant_code" :placeholder="tt('choose_plant')">
                                          <el-option class="select-danger" :value="pc.plant_code" :label="pc.description" :key="pc.plant_code" v-for="pc in  plantCode"></el-option>
                                      </el-select> -->
                                  </base-input>
                              </div>
                          </div>
      
                          <div class="row">
                              <div class="col-md-6">
                                  <label class="form-control-label">{{ tt('storage_location') }} <span class="text-danger">*</span></label>
                                  <base-input :name="tt('storage_location')" rules="required">
                                      <input :disabled="form.a3.disabled" class="form-control form-control-sm" v-model="mcrItem.location_code">
                                      
                                      <!-- <el-select :disabled="form.a3.disabled" class="select-danger" v-model="inputDraftListA3.location_code" :placeholder="tt('choose_storage_location')">
                                          <el-option class="select-danger" :value="lc.location_code" :label="lc.location_description" :key="lc.location_code" v-for="lc in  locationCode"></el-option>
                                      </el-select> -->
                                  </base-input>
                              </div>
                              <div class="col-md-6">
                                  <label class="form-control-label">{{ tt('valuation_type') }} <span class="text-danger">*</span></label>
                                  <base-input :name="tt('valuation_type')" rules="required">
                                      <input :disabled="form.a3.disabled" class="form-control form-control-sm" v-model="mcrItem.location_code">
                                      <!-- <el-select :disabled="form.a3.disabled" class="select-danger" v-model="inputDraftListA3.valuation_type_code" :placeholder="tt('choose_valuation_type')">
                                          <el-option class="select-danger" :value="vt.valuation_type_code" :label="vt.valuation_type_description" :key="vt.valuation_type_code" v-for="vt in  valuationType"></el-option>
                                      </el-select> -->
                                  </base-input>
                              </div>
                          </div>
      
                          <div class="card z-depth-0 bordered">
                              <div class="card-header bg-secondary border" id="headingOneMpnA3">
                                  <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                      <div class="row">
                                          <div class="col-md-6">
                                              {{tt('manufacturer_part_number')}}
                                          </div>
                                      </div>
                                  </h4>
                              </div>
                              <div>
                                  <div class="card-body border">
                                      <div class="table-responsive">
                                          <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="mpnA3" > <!--:data="table.item"-->
                                            <el-table-column :label="tt('manufacturer_code')" :prop="tt('manufacturer_code')" min-width="200px" sortable>
                                                <template v-slot="{row}">
                                                     {{ row.manufacture_code }}
                                                </template>
                                            </el-table-column>
                    
                                            <el-table-column :label="tt('mpn')" :prop="tt('mpn')" min-width="100px" sortable>
                                                <template v-slot="{row}">
                                                    {{ row.mpn }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="tt('type')" :prop="tt('type')" min-width="90px" sortable>
                                                <template v-slot="{row}">
                                                    {{ row.manufacture_type }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="tt('note')" :prop="tt('note')" min-width="90px" sortable>
                                                <template v-slot="{row}">
                                                    {{ row.manufacture_note }}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                          
                                      </div>
                                  </div>
                              </div>
                          </div> 
                            <div class="card z-depth-0 bordered">
                                <div class="card-header bg-secondary border" id="headingOneEqiA3">
                                    <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                        <div class="row">
                                            <div class="col-md-6">
                                                {{tt('equipment_&_quantity_install')}}
                                            </div>
                                        </div>
                                    </h4>
                                </div>
                                <div class="card-body border">
                                    <div class="table-responsive">
                                        <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="equipA3" > <!--:data="table.item"-->
                                            <el-table-column :label="tt('plant')" :prop="tt('plant')" min-width="100px" sortable>
                                                <template v-slot="{row}">
                                                     {{ row.plant_code }}
                                                </template>
                                            </el-table-column>
                    
                                            <el-table-column :label="tt('equipment_code')" :prop="tt('equipment_code')" min-width="150px" sortable>
                                                <template v-slot="{row}">
                                                    {{ row.equipment_code }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="tt('quantity_install')" :prop="tt('quantity_install')" min-width="125px" sortable>
                                                <template v-slot="{row}">
                                                    {{ row.qty_installed }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="tt('drawing_number')" :prop="tt('drawing_number')" min-width="125px" sortable>
                                                <template v-slot="{row}">
                                                    {{ row.drawing_number }}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                        
                                    </div>
                                </div>
                            </div>
      
                          <label class="form-control-label mt-4">{{ tt('note') }}</label>
                          <base-input :name="tt('note')" rules="required">
                              <textarea :disabled="form.a3.disabled" class="form-control" rows="5" v-model="mcrItem.note"></textarea>
                          </base-input> 
                      </div>
                  </div>

              </tab-pane>
              
              
              
              <!-- Tab Cataloguer -->
              <tab-pane title="Cataloguer Create" @click="sayhay()">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
                                    <!-- <base-input :name="tt('item_type')" rules="required">
                                        <el-autocomplete
                                        class="inline-input col-md-12"
                                        v-model="inputDraftListB4.material_number"
                                        :fetch-suggestions="querySearchMatNum"
                                        :placeholder="tt('choose_material_number')"
                                        :trigger-on-focus="false"
                                        @select="handleSelectMaterial"
                                        size="large"
                                        ></el-autocomplete>
                                    </base-input> -->
                                    <base-input :name="tt('material_number')" :placeholder="tt('material_number')" v-model="inputDraftListB4.material_number" disabled></base-input>
                                </div>
                                <div class="col-md-6">
                                    <!-- <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label> -->
                                    <!-- <base-input :name="tt('bom_or_non_bom')" rules="required">
                                        <el-select class="select-danger" v-model="draftList.bom_non_bom" :placeholder="tt('choose_bom_or_non_bom')">
                                            <el-option class="select-danger" :value="bnb" :label="bnb" :key="bnb" v-for="bnb in  bomNonBom"></el-option>
                                        </el-select>
                                    </base-input> -->
                                    <!-- <base-input :name="tt('description')" :placeholder="tt('description')" v-model="inputDraftListB4.description" rules="required"></base-input> -->
                                </div>
                            </div>
                            
                            
                            <!--Blok Manufaktur -->
                            <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1">
                                <div class="row">
                                    <div class="col-md-6">
                                        {{tt('manufacturer_part_number')}}
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <base-button size="sm" type="default" @click="addMpnB4()">{{ tt('add_new') }}</base-button>
                                    </div>
                                </div>
                            </h4>
                            <table class="table align-items-center table-flush table-bordered">
                                <thead class="card-header bg-primary">
                                    <tr>
                                        <th class="text-white">{{tt('manufacturer_code')}}</th>
                                        <th class="text-white">{{tt('mpn')}}</th>
                                        <th class="text-white">{{tt('type')}}</th>
                                        <th class="text-white">{{tt('note')}}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(mpn, key) in manufacturePartNumberB4.manufacture_code" v-bind:key="mpn.manufacture_code">
                                        <td>
                                            <!-- <input class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')"
                                                :disabled="manufacturePartNumberB4.hapus[key]=== '1'" 
                                                v-model="manufacturePartNumberB4.manufacture_description[key]" rules="required"> -->
                                                
                                            <el-autocomplete
                                                class="inline-input col-md-12"
                                                v-model="manufacturePartNumberB4.manufacture_description[key]"
                                                :disabled="manufacturePartNumberB4.hapus[key]=== '1'" 
                                                :fetch-suggestions="querySearchManuf"
                                                :placeholder="tt('choose_material_number')"
                                                :trigger-on-focus="false"
                                                @select="handleSelectManuf"
                                                size="large"
                                                ></el-autocomplete>
                                            <!-- <select class="form-control-sm" 
                                            :disabled="manufacturePartNumberB4.hapus[key]=== '1'" 
                                            v-model="manufacturePartNumberB4.manufacture_code[key]">
                                                <option value="">Choose Manufacture</option>
                                                <option :value="m.manufacture_code" v-for="m in manufacture" v-bind:key="m.id">
                                                    {{m.manufacture_name}}</option>
                                            </select> -->
                                        </td>
                                        <td><input class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')"
                                                :disabled="manufacturePartNumberB4.hapus[key]=== '1'" 
                                                v-model="manufacturePartNumberB4.mpn[key]" rules="required"></td>
                                        <td>
                                            <select class="form-control-sm" 
                                            :disabled="manufacturePartNumberB4.hapus[key]=== '1'" 
                                            v-model="manufacturePartNumberB4.manufacture_type[key]">
                                                <option value="">Choose Type</option>
                                                <option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{mt.description}}</option>
                                            </select>
                                        </td>
                                        <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')"
                                                v-model="manufacturePartNumberB4.manufacture_note[key]" rules="required"></td>
                                        <td><i v-if="manufacturePartNumberB4.hapus[key]=== '0'" class="fa fa-trash text-danger" @click="removeMpnB4(key)"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- ENd Of Blok Manufaktur -->
                            
                            <!-- Blok Equipment -->
                            <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        {{tt('equipment_&_quantity_install')}}
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <base-button size="sm" type="default" @click="addEqiB4">{{ tt('add_new') }}</base-button>
                                    </div>
                                </div>
                            </h4>
                            <div class="table-responsive">
                                <table class="table align-items-center table-flush table-bordered">
                                    <thead class="card-header bg-primary">
                                        <tr>
                                            <th class="text-white">{{tt('plant')}}</th>
                                            <th class="text-white">{{tt('equipment_code')}}</th>
                                            <th class="text-white">{{tt('quantity_install')}}</th>
                                            <th class="text-white">{{tt('drawing_number')}}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(eqi, key) in equipmentQuantityInstallB4.equipment_code" v-bind:key="eqi.equipment_code">
                                            <td>
                                                <select class="form-control-sm" 
                                                :disabled="equipmentQuantityInstallB4.hapus[key]=== '1'" v-model="equipmentQuantityInstallB4.plant_code[key]">
                                                    <option value="">Choose Plant</option>
                                                    <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.description">
                                                        {{pc.plant_code +' - '+ pc.description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select class="form-control-sm" 
                                                v-model="equipmentQuantityInstallB4.equipment_code[key]"
                                                :disabled="equipmentQuantityInstallB4.hapus[key]=== '1'">
                                                    <option value="">Choose Equipment Code</option>
                                                    <option :value="ec.equipment_code" v-for="ec in equipmentCode"
                                                        v-bind:key="ec.equipment_code">{{ec.equipment_name}}</option>
                                                </select>
                                            </td>
                                            <td><input class="form-control form-control-sm" :name="tt('qty_install')"
                                                    :placeholder="tt('qty_install')" v-model="equipmentQuantityInstallB4.qty_installed[key]"
                                                    :disabled="equipmentQuantityInstallB4.hapus[key]=== '1'"
                                                    rules="required"></td>
                                            <td><input class="form-control form-control-sm" :name="tt('drawing_number')"
                                                    :placeholder="tt('drawing_number')" v-model="equipmentQuantityInstallB4.drawing_number[key]"
                                                    :disabled="equipmentQuantityInstallB4.hapus[key]=== '1'"
                                                    rules="required"></td>
                                            <td><i v-if="equipmentQuantityInstallB4.hapus[key]=== '0'" class="fa fa-trash text-danger" @click="removeEqiB4(key)"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- End Of Blok Equipment  -->
                            
                            
                            <!-- Blok Plant -->
                            <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        {{tt('plant')}}
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <base-button size="sm" type="default" @click="addPlantB4">{{ tt('add_new') }}</base-button>
                                    </div>
                                </div>
                            </h4>
                            <div class="table-responsive">
                                <table class="table align-items-center table-flush table-bordered">
                                    <thead class="card-header bg-primary">
                                        <tr>
                                            <th class="text-white">{{tt('plant')}}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(pl, key) in plantLevelB4.plant" v-bind:key="pl.plant">
                                                <td>
                                                    <select class="form-control-sm" 
                                                    :disabled="plantLevelB4.hapus[key]=== '1'"
                                                    v-model="plantLevelB4.plant[key]">
                                                        <option value="">Choose Plant</option>
                                                        <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.plant_code">
                                                            {{pc.plant_code +' - '+ pc.description}}</option>
                                                    </select>
                                                </td>
                                                <td><i v-if="plantLevelB4.hapus[key]=== '0'" class="fa fa-trash text-danger" @click="removePlantB4(key)"></i></td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- End Of Blok Plant -->
                            
                            
                            <!-- Blok storage_location -->
                            <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        {{tt('storage_location')}}
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <base-button size="sm" type="default" @click="addSlocB4()" >{{ tt('add_new') }}</base-button>
                                    </div>
                                </div>
                            </h4>
                            <div class="table-responsive">
                                <table class="table align-items-center table-flush table-bordered">
                                    <thead class="card-header bg-primary">
                                        <tr>
                                             <th class="text-white">{{tt('plant')}}</th>
                                            <th class="text-white">{{tt('storage_location')}}</th>
                                            <!-- <th class="text-white">{{tt('warehouse_number')}}</th> -->
                                            <!-- <th class="text-white">{{tt('storage_type')}}</th> -->
                                            <th class="text-white">{{tt('bin_location')}}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(sloc, key) in storageLocation.storage_location" v-bind:key="sloc.storage_location">
                                            <td>
                                                <select class="form-control-sm" 
                                                :disabled="storageLocation.hapus[key]=== '1'" v-model="storageLocation.plant_code[key]">
                                                    <option value="">Choose Plant</option>
                                                    <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.description">
                                                        {{pc.plant_code +' - '+ pc.description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select class="form-control-sm" 
                                                :disabled="storageLocation.hapus[key]=== '1'" v-model="storageLocation.storage_location[key]">
                                                    <option value="">Choose Storage Location</option>
                                                    <option :value="lc.location_code" v-for="lc in locationCode" v-bind:key="lc.location_code">
                                                        {{lc.location_code +' - '+ lc.location_description}}</option>
                                                </select>
                                            <!-- <input class="form-control form-control-sm" :name="tt('manufacturer_code')" :placeholder="tt('manufacturer_code')" :disabled="storageLocation.hapus[key]=== '1'"  v-model="storageLocation.storage_location[key]" rules="required"> -->
                                            </td>
                                            <!-- <td>
                                                <select class="form-control-sm" 
                                                :disabled="storageLocation.hapus[key]=== '1'" v-model="storageLocation.warehouse_number[key]">
                                                    <option value="">Choose WareHouse</option>
                                                    <option :value="wh.warehouse_number" v-for="wh in warehouse" v-bind:key="wh.warehouse_number">
                                                        {{wh.warehouse_description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select class="form-control-sm" 
                                                :disabled="storageLocation.hapus[key]=== '1'" v-model="storageLocation.storage_type[key]">
                                                    <option value="">Choose Storage Type</option>
                                                    <option :value="st.storage_type_code" v-for="st in storageType" v-bind:key="st.storage_type_code">
                                                        {{st.storage_type_name}}</option>
                                                </select>
                                            </td> -->
                                            <td>
                                                <select class="form-control-sm" 
                                                :disabled="storageLocation.hapus[key]=== '1'" v-model="storageLocation.bin_location[key]">
                                                    <option value="">Choose Storage Location</option>
                                                    <option :value="bl.bin_code" v-for="bl in binloc" v-bind:key="bl.bin_code">
                                                        {{bl.bin_code +' - '+ bl.bin_name}}</option>
                                                </select>
                                                <!-- <input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" :disabled="storageLocation.hapus[key]=== '1'"  v-model="storageLocation.bin_location[key]" rules="required"> -->
                                            </td>
                                            <td><i v-if="storageLocation.hapus[key]=== '0'"  @click="removeSlocB4(key)"  class="fa fa-trash text-danger"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- End Of Blok storage_location -->
                            
                            
                            <!-- Blok Sales -->
                            <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        {{tt('sales')}}
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <base-button size="sm" type="default" @click="addSB4">{{ tt('add_new') }}</base-button>
                                    </div>
                                </div>
                            </h4>
                            <div class="table-responsive">
                                <table class="table align-items-center table-flush table-bordered">
                                    <thead class="card-header bg-primary">
                                        <tr>
                                            <th class="text-white">{{tt('plant')}}</th>
                                            <th class="text-white">{{tt('sales_org')}}</th>
                                            <th class="text-white">{{tt('distr_channel')}}</th>
                                            <th class="text-white">{{tt('division')}}</th>
                                            <!-- <th class="text-white">{{tt('tax_cat')}}</th> -->
                                            <th class="text-white">{{tt('tax_class')}}</th>
                                            <th class="text-white">{{tt('gen_item_cat_grp')}}</th>
                                            <th class="text-white">{{tt('account_assign_grp')}}</th>
                                            <th class="text-white">{{tt('item_cat_grp')}}</th>
                                            <th class="text-white">{{tt('trans_group')}}</th>
                                            <th class="text-white">{{tt('loading_group')}}</th>
                                            <th class="text-white">{{tt('profit_center')}}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(s, key) in salesB4.sales_org" v-bind:key="s.sales_org">
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control form-control-sm" v-model="salesB4.sls_plant_code[key]">
                                                    <option value="">Choose Plant</option>
                                                    <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.description">
                                                    {{pc.plant_code +' - '+ pc.description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control form-control-sm" v-model="salesB4.sales_org[key]">
                                                    <option value="">Choose Sales Organization</option>
                                                    <option :value="so.sales_org_code" v-for="so in salesOrganization" v-bind:key="so.sales_org_code">{{so.sales_org_code +' - '+ so.sales_org_description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control-sm" v-model="salesB4.distr_channel[key]">
                                                    <option value="">Choose Distribution Channel</option>
                                                    <option :value="dc.distr_channel_code" v-for="dc in distributionChannel" v-bind:key="dc.distr_channel_code">{{dc.distr_channel_code +' - '+ dc.distr_channel_description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control-sm" v-model="salesB4.division[key]">
                                                    <option value="">Choose Division</option>
                                                    <option :value="d.division_code" v-for="d in division" v-bind:key="d.division_code">{{d.division_code +' - '+ d.division_desc}}</option>
                                                </select>
                                            </td>
                                            <!-- <td><input :disabled="salesB4.hapus[key]=== '1'"  class="form-control form-control-sm" :name="tt('tax_cat')" :placeholder="tt('tax_cat')" v-model="salesB4.tax_cat[key]" rules="required"></td> -->
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control-sm" v-model="salesB4.tax_class[key]">
                                                    <option value="">Choose Tax Classification</option>
                                                    <option :value="tc.tax_class_code" v-for="tc in taxClassification" v-bind:key="tc.tax_class_code">{{tc.tax_class_code +' - '+ tc.tax_class_desc}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control-sm" v-model="salesB4.gen_item_cat_grp[key]">
                                                    <option value="">Choose Item Category Group</option>
                                                    <option :value="icg.item_cat_group_code" v-for="icg in itemCategoryGroup" v-bind:key="icg.item_cat_group_code">{{icg.item_cat_group_code +' - '+ icg.item_cat_group_desc}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control-sm" v-model="salesB4.account_assign_grp[key]">
                                                    <option value="">Choose Account Assignment Group</option>
                                                    <option :value="aag.acc_ass_group_code" v-for="aag in accountAssignmentGroup" v-bind:key="aag.acc_ass_group_code">{{aag.acc_ass_group_code +' - '+ aag.acc_ass_group_code}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control-sm" v-model="salesB4.item_cat_grp[key]">
                                                    <option value="">Choose Item Category Group</option>
                                                    <option :value="icg.item_cat_group_code" v-for="icg in itemCategoryGroup" v-bind:key="icg.item_cat_group_code">{{icg.item_cat_group_code +' - '+ icg.item_cat_group_desc}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control-sm" v-model="salesB4.trans_group[key]">
                                                    <option value="">Choose Transportation Group</option>
                                                    <option :value="tg.trans_group_code" v-for="tg in transportationGroup" v-bind:key="tg.trans_group_code">{{tg.trans_group_code +' - '+ tg.trans_group_desc}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control-sm" v-model="salesB4.loading_group[key]">
                                                    <option value="">Choose Loading Group</option>
                                                    <option :value="lg.loading_group_code" v-for="lg in loadingGroup" v-bind:key="lg.loading_group_code">{{lg.loading_group_code +' - ' + lg.loading_group_desc}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="salesB4.hapus[key]=== '1'"  class="form-control-sm" v-model="salesB4.profit_center[key]">
                                                    <option value="">Choose Profit Center</option>
                                                    <option :value="pc.profit_center_code" v-for="pc in profitCenter" v-bind:key="pc.profit_center_code">{{pc.profit_center_code +' - '+ pc.profit_center_description}}</option>
                                                </select>
                                            </td>
                                            <td><i v-if="salesB4.hapus[key]=== '0'"  class="fa fa-trash text-danger" @click="removeSB4(key)"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- End Of Blok Sales -->
                            
                            
                            <!-- Blok MRP -->
                            <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        {{tt('mrp')}}
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <base-button size="sm" type="default" @click="addMrpB4">{{ tt('add_new') }}</base-button>
                                    </div>
                                </div>
                            </h4>
                            <div class="table-responsive">
                                <table class="table align-items-center table-flush table-bordered">
                                    <thead class="card-header bg-primary">
                                        <tr>
                                            <th class="text-white">{{tt('plant')}}</th>
                                            <th class="text-white">{{tt('abc_indicator')}}</th>
                                            <th class="text-white">{{tt('mrp_type')}}</th>
                                            <th class="text-white">{{tt('mrp_controller')}}</th>
                                            <th class="text-white">{{tt('lot_size')}}</th>
                                            <th class="text-white">{{tt('min')}}</th>
                                            <th class="text-white">{{tt('max')}}</th>
                                            <th class="text-white">{{tt('procurement_type')}}</th>
                                            <th class="text-white">{{tt('special_proc')}}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(mrp, key) in mrpB4.abc_indicator" v-bind:key="mrp.abc_indicator">
                                            <td>
                                                <select class="form-control-sm" 
                                                :disabled="mrpB4.hapus[key]=== '1'" v-model="mrpB4.plant_mrp[key]">
                                                    <option value="">Choose Plant</option>
                                                    <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.description">
                                                    {{pc.plant_code +' - '+ pc.description}}</option>
                                                </select>

                                            </td>
                                            <td>
                                                <select class="form-control-sm" :disabled="mrpB4.hapus[key]=== '1'"  v-model="mrpB4.abc_indicator[key]">
                                                    <option value="">Choose ABC Indicator</option>
                                                    <option :value="ai.abc_indicator_code" v-for="ai in abcIndicator" v-bind:key="ai.abc_indicator_code">{{ai.abc_indicator_code +' - ' + ai.abc_indicator_desc}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select class="form-control-sm" :disabled="mrpB4.hapus[key]=== '1'" v-model="mrpB4.mrp_type[key]">
                                                    <option value="">Choose MRP Type</option>
                                                    <option :value="mt.mrp_type_code" v-for="mt in mrpTypes" v-bind:key="mt.mrp_type_code">{{mt.mrp_type_code +' - '+ mt.mrp_type_desc}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select class="form-control-sm" :disabled="mrpB4.hapus[key]=== '1'" v-model="mrpB4.mrp_controller[key]">
                                                    <option value="">Choose MRP Controller</option>
                                                    <option :value="mc.mrp_controller_code" v-for="mc in mrpController" v-bind:key="mc.mrp_controller_code">{{mc.mrp_controller_code +' - '+ mc.mrp_controller_desc}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select class="form-control-sm" :disabled="mrpB4.hapus[key]=== '1'" v-model="mrpB4.lot_size[key]">
                                                    <option value="">Choose Lot Size</option>
                                                    <option :value="ls.lot_size_code" v-for="ls in lotSize" v-bind:key="ls.lot_size_code">{{ls.lot_size_code +' - '+ ls.lot_size_desc}}</option>
                                                </select>
                                            </td>
                                            <td><input class="form-control form-control-sm" :disabled="mrpB4.hapus[key]=== '1'" :name="tt('min')" :placeholder="tt('min')" v-model="mrpB4.min[key]" rules="required"></td>
                                            <td><input :disabled="mrpB4.hapus[key]=== '1'" class="form-control form-control-sm" :name="tt('max')" :placeholder="tt('max')" v-model="mrpB4.max[key]" rules="required"></td>
                                            <td>
                                                <select :disabled="mrpB4.hapus[key]=== '1'" class="form-control-sm" v-model="mrpB4.procurement_type[key]">
                                                    <option value="">Choose Procurement Type</option>
                                                    <option :value="pt.proc_type_code" v-for="pt in procurementType" v-bind:key="pt.proc_type_code">{{pt.proc_type_code +' - '+ pt.proc_type_desc}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="mrpB4.hapus[key]=== '1'" class="form-control-sm" v-model="mrpB4.special_proc[key]">
                                                    <option value="">Choose Special Procurement Type</option>
                                                    <option :value="spt.spc_proc_type_code" v-for="spt in specialProcurementType" v-bind:key="spt.spc_proc_type_code">{{spt.spc_proc_type_code +' - '+ spt.spc_proc_type_desc}}</option>
                                                </select>
                                            </td>
                                            <td><i v-if="mrpB4.hapus[key]=== '0'" class="fa fa-trash text-danger" @click="removeMrpB4(key)"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- End Of Blok MRP -->
                            
                            
                            
                            <!-- Blok accounting -->
                            <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        {{tt('accounting')}}
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <base-button size="sm" type="default" @click="addAB2">{{ tt('add_new') }}</base-button>
                                    </div>
                                </div>
                            </h4>
                            <div class="table-responsive">
                                <table class="table align-items-center table-flush table-bordered">
                                    <thead class="card-header bg-primary">
                                        <tr>
                                            <th class="text-white">{{tt('plant')}}</th>
                                            <th class="text-white">{{tt('valuation_class')}}</th>
                                            <th class="text-white">{{tt('valuation_category')}}</th>
                                            <th class="text-white">{{tt('valuation_type')}}</th>
                                            <th class="text-white">{{tt('price_control')}}</th>
                                            <th class="text-white">{{tt('price_determination')}}</th>
                                            <th class="text-white">{{tt('price_unit')}}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(vc, key) in accountingB4.valuation_class" v-bind:key="vc.code">
                                             <td>
                                                <select :disabled="accountingB4.hapus[key]=== '1'"  class="form-control-sm" v-model="accountingB4.plant_accounting[key]">
                                                    <option value="">Choose Plant</option>
                                                    <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.description">
                                                    {{pc.plant_code +' - '+ pc.description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="accountingB4.hapus[key]=== '1'"  class="form-control-sm" v-model="accountingB4.valuation_class[key]">
                                                    <option value="">Choose Valution Class</option>
                                                    <option :value="vcl.code" v-for="vcl in valuationClass" v-bind:key="vcl.code">{{vcl.code +' - '+ vcl.description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="accountingB4.hapus[key]=== '1'"  class="form-control-sm" v-model="accountingB4.valuation_category[key]">
                                                    <option value="">Choose Valution Category</option>
                                                    <option :value="vca.valuation_category_code" v-for="vca in valuationCategory" v-bind:key="vca.valuation_category_code">{{vca.valuation_category_code +' - '+ vca.valuation_category_description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="accountingB4.hapus[key]=== '1'"  class="form-control-sm" v-model="accountingB4.valuation_type[key]">
                                                    <option value="">Choose Valution Type</option>
                                                    <option :value="vt.valuation_type_code" v-for="vt in valuationType" v-bind:key="vt.valuation_type_code">{{vt.valuation_type_code +' - '+ vt.valuation_type_description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="accountingB4.hapus[key]=== '1'"  class="form-control-sm" v-model="accountingB4.price_control[key]">
                                                    <option value="">Choose Price Control</option>
                                                    <option :value="pc.price_control_code" v-for="pc in priceControl" v-bind:key="pc.price_control_code">{{pc.price_control_code +' - '+ pc.price_control_description}}</option>
                                                </select>
                                            </td>
                                            <td><input :disabled="accountingB4.hapus[key]=== '1'"  class="form-control form-control-sm" :name="tt('price_determination')" :placeholder="tt('price_determination')" v-model="accountingB4.price_determination[key]" rules="required">
                                            </td>
                                            <td><input :disabled="accountingB4.hapus[key]=== '1'"  class="form-control form-control-sm" :name="tt('price_unit')" :placeholder="tt('price_unit')" v-model="accountingB4.price_unit[key]" rules="required"></td>
                                            <td><i v-if="accountingB4.hapus[key]=== '0'"  class="fa fa-trash text-danger" @click="removeAB2(key)"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- End Of Blok accounting -->
                            
                            <!-- Blok Purchasing -->
                            <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        {{tt('purchasing')}}
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <base-button size="sm" type="default" @click="addPurchB4">{{ tt('add_new') }}</base-button>
                                    </div>
                                </div>
                            </h4>
                            <div class="table-responsive">
                                <table class="table align-items-center table-flush table-bordered">
                                    <thead class="card-header bg-primary">
                                        <tr>
                                            <th class="text-white">{{tt('plant')}}</th>
                                            <th class="text-white">{{tt('purchasing_group')}}</th>
                                            <th class="text-white">{{tt('order_unit')}}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       <tr v-for="(pc, key) in purchasingB4.purchasing_group" v-bind:key="pc.code">
                                            <td>
                                                <select :disabled="purchasingB4.hapus[key]=== '1'"  class="form-control-sm" v-model="purchasingB4.plant_purchasing[key]">
                                                    <option value="">Choose Plant</option>
                                                    <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.description">
                                                    {{pc.plant_code +' - '+ pc.description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select :disabled="purchasingB4.hapus[key]=== '1'"  class="form-control-sm" v-model="purchasingB4.purchasing_group[key]">
                                                    <option value="">Choose Purchasing Group</option>
                                                    <option :value="vcl.purchasing_group_code" v-for="vcl in purchasingGroup" v-bind:key="vcl.purchasing_group_code">{{vcl.purchasing_group_code +' - '+ vcl.purchasing_group_description}}</option>
                                                </select>
                                            </td>
                                            <td>
                                            <input :disabled="purchasingB4.hapus[key]=== '1'"   class="form-control form-control-sm" :name="tt('price_unit')" :placeholder="tt('order_unit')" v-model="purchasingB4.order_unit[key]" rules="required">
                                            </td>
                                            <td>
                                            <i v-if="purchasingB4.hapus[key]=== '0'" class="fa fa-trash text-danger" @click="removePurchB4(key)"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <!-- End Of Blok Purchasing -->
                            
                            <label class="form-control-label mt-4">{{ tt('note') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('note')" rules="required">
                                <textarea v-model="inputDraftListB4.note" class="form-control" rows="5"></textarea>
                            </base-input>
                        </div>
                    </div>
              </tab-pane>
            </tabs>
          </div>


        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <base-button size="sm" type="warning"
            v-on:click="this.$router.push('/material/aproval-page/'+this.mcr_code+'/'+this.token)">Approval Pages
          </base-button>
          <base-button size="sm" @click="saveB2('edit')" type="primary">{{ tt('process') }}</base-button>
        </div>
      </div>
    </div>





  </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import manrefType from '@/services/master/manrefType.service';
    import manufacture from '@/services/master/manufacture.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import plantCode from '@/services/master/plantCode.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    
    
    import locationCode from '@/services/master/locationCode.service';
    import warehouse from '@/services/master/warehouse.service';
    import storageType from '@/services/master/storageType.service';
    import binloc from '@/services/master/bin.service';
    
    import salesOrganization from '@/services/master/salesOrganization.service';
    import distributionChannel from '@/services/master/distributionChannel.service';
    import division from '@/services/master/division.service';
    import taxClassification from '@/services/master/taxClassification.service';
    import itemCategoryGroup from '@/services/master/itemCategoryGroup.service';
    import accountAssignmentGroup from '@/services/master/accountAssignmentGroup.service';
    import transportationGroup from '@/services/master/transportationGroup.service';
    import loadingGroup from '@/services/master/loadingGroup.service';
    import profitCenter from '@/services/master/profitCenter.service';
    
    import abcIndicator from '@/services/master/abcIndicator.service';
    import mrpTypes from '@/services/master/mrpTypes.service';
    import mrpController from '@/services/master/mrpController.service';
    import lotSize from '@/services/master/lotSize.service';
    import procurementType from '@/services/master/procurementType.service';
    import specialProcurementType from '@/services/master/specialProcurementType.service';
    
    import valuationClass from '@/services/master/valuationClass.service';
    import valuationCategory from '@/services/master/valuationCategory.service';
    import valuationType from '@/services/master/valuationType.service';
    import priceControl from '@/services/master/priceControl.service';
    
    import purchasingGroup from '@/services/master/purchasingGroup.service';
    

    export default {        
        data() {
            return { 
                loadTimeout: null,  
                btnUpdate: {
                    onLoading: false
                },        
                formMcrInformation: {
                    show: false
                },                
                form: {
                    a3: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },

                    b4: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    
                },
                mcrInformation: {},
                mcrItem: {},
                mpnA3:[],
                equipA3:[],
                
                //================ BLok B4
                inputDraftListB4: {
                    id:'',
                    material_number: '',
                    description: '',
                    note: '',
                },
                
                manufacturePartNumberB4: {
                    manufacture_code: [],
                    manufacture_description: [],
                    mpn: [],
                    manufacture_type: [],
                    manref_description: [],
                    manufacture_note: [],
                    hapus:[],
                },
                equipmentQuantityInstallB4: {
                    plant_code: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                    hapus:[],
                    
                },
                plantLevelB4: {
                    plant: [],
                    hapus:[],
                },
                storageLocation: {
                    plant_code: [],
                    storage_location: [],
                    warehouse_number: [],
                    storage_type: [],
                    bin_location: [],
                    hapus:[],
                },
                salesB4: {
                    sls_plant_code:[],
                    sales_org: [],
                    distr_channel: [],
                    division: [],
                    //tax_cat: [],
                    tax_class: [],
                    gen_item_cat_grp: [],
                    account_assign_grp: [],
                    item_cat_grp: [],
                    trans_group: [],
                    loading_group: [],
                    profit_center: [],
                    hapus:[],
                },
                
                mrpB4: {
                    plant_mrp:[],
                    abc_indicator: [],
                    mrp_type: [],
                    mrp_controller: [],
                    lot_size: [],
                    min: [],
                    max: [],
                    procurement_type: [],
                    special_proc: [],
                    hapus:[],
                },
                accountingB4: {
                    plant_accounting:[],
                    valuation_class: [],
                    valuation_category: [],
                    valuation_type: [],
                    price_control: [],
                    price_determination: [],
                    standard_price:[],
                    price_unit: [],
                    hapus:[],
                },
                purchasingB4: {
                    plant_purchasing: [],
                    purchasing_group: [],
                    order_unit: [],
                    hapus:[],
                },
                
                
                valuesmat:[],
                valuesman:[],
                manufPart4:[],
                sLoc4:[],
                sale4:[],
                mrp4:[],
                acct4:[],
                purch4:[],
                
                manufacture: {},
                manrefType: {},
                matnum:'',
                plantCode: {},
                equipmentCode: {},
                drawingMaster: {},
                
                locationCode:{},
                warehouse:{},
                storageType:{},
                binloc:{},
                
                salesOrganization: {},
                distributionChannel: {},
                division: {},
                taxClassification: {},
                itemCategoryGroup: {},
                accountAssignmentGroup: {},
                transportationGroup: {},
                loadingGroup: {},
                profitCenter: {},
                
                
                abcIndicator: {},
                mrpTypes: {},
                mrpController: {},
                lotSize: {},
                procurementType: {},
                specialProcurementType: {},
                
                valuationClass: {},
                valuationCategory: {},
                valuationType: {},
                priceControl: {},
                
                purchasingGroup:{},
                
                mcr_code: window.location.hash.split('/')[3],
                token: window.location.hash.split('/')[4],
                mcritemcode: window.location.hash.split('/')[5],
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        watch:{
            matnum: function(newMatnum){
                this.getMaterialSpek()
            }
        },
        mounted() {
            this.get()
            this.getManrefType()
            this.getManufacture()
            this.getPlantCode()
            this.getEquipmentCode()
            this.getMcrDetilA1(this.mcritemcode);
            
            //load data sloc
            this.getStorageLoc()
            this.getWareHouseNo()
            this.getStorageType()
            this.getBinLoc()
            
            //========================== Sales ==========================
            this.getSalesOrganization();
            this.getDistributionChannel();
            this.getDivision();
            this.getTaxClassification();
            this.getItemCategoryGroup();
            this.getAccountAssignmentGroup();
            this.getTransportationGroup();
            this.getLoadingGroup();
            this.getProfitCenter();
            
            this.getAbcIndicator();
            this.getMrpTypes();
            this.getMrpController();
            this.getLotSize();
            this.getProcurementType();
            this.getSpecialProcurementType();
            
            
            this.getValuationClass();
            this.getValuationCategory();
            this.getValuationType();
            this.getPriceControl();
            
            this.getPurchasingGroup();
            
        },
        
        methods: {
            get() {
                let context = this; 
                context.form.a3.disabled = true;
                Api(context, draftList.getMcrItem(context.mcr_code, context.token)).onSuccess(function(response) {                 
                    context.mcrItem = response.data.data.mcr_item[0];
                    context.inputDraftListB4.material_number = response.data.data.mcr_item[0].material_number
                    context.inputDraftListB4.id = response.data.data.mcr_item[0].id
                    context.mcrInformation = response.data.data.mcr[0];
                    context.matnum = response.data.data.mcr_item[0].material_number
                })
                .call()
                
            },
            
            //============================Blok B4================================
            getPlantCode() {
                let context = this;               
                Api(context, plantCode.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.plantCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantCode = [];
                    }
                })
                .call()
            },
            getEquipmentCode() {
                let context = this;               
                Api(context, equipmentCode.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.equipmentCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.equipmentCode = [];
                    }
                })
                .call()
            },
            getMaterialNumber() {
                let context = this;               
                Api(context, materialNumber.getMaterial({per_page: 'none'})).onSuccess(function(response) {    
                    //context.materialNumber = response.data.data;
                    var values = [];                                
                    response.data.data.forEach(function(value, j) {
                        values[j] = {
                            'link' : value['material_number'],
                            'value': value['material_number'] + '-' + value['short_description']
                        }
                    });
                    context.valuesmat = values;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        //context.materialNumber = [];
                        context.valuesmat = [];
                    }
                })
                .call()
            },
            
            getManufacture() {
                let context = this;               
                Api(context, manufacture.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.manufacture = response.data.data.data.data;
                    var values = [];                                
                    response.data.data.data.data.forEach(function(value, j) {
                        values[j] = {
                            'link' : value['manufacture_code'],
                            'value': value['manufacture_name']
                        }
                    });
                    context.valuesman = values;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manufacture = [];
                        //context.valuesman = [];
                    }
                })
                .call()
            },
            
            getStorageLoc() {
                let context = this;               
                Api(context, locationCode.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.locationCode = response.data.data.data.data;
                    // var values = [];                                
                    // response.data.data.forEach(function(value, j) {
                    //     values[j] = {
                    //         'link' : value['material_number'],
                    //         'value': value['material_number'] + '-' + value['short_description']
                    //     }
                    // });
                    // context.valuesmat = values;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.locationCode = [];
                        //context.valuesmat = [];
                    }
                })
                .call()
            },
            
            getWareHouseNo() {
                let context = this;               
                Api(context, warehouse.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.warehouse = response.data.data.data.data;
                    // var values = [];                                
                    // response.data.data.forEach(function(value, j) {
                    //     values[j] = {
                    //         'link' : value['material_number'],
                    //         'value': value['material_number'] + '-' + value['short_description']
                    //     }
                    // });
                    // context.valuesmat = values;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.warehouse = [];
                        //context.valuesmat = [];
                    }
                })
                .call()
            },
            
            getStorageType() {
                let context = this;               
                Api(context, storageType.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.storageType = response.data.data.data.data;
                    // var values = [];                                
                    // response.data.data.forEach(function(value, j) {
                    //     values[j] = {
                    //         'link' : value['material_number'],
                    //         'value': value['material_number'] + '-' + value['short_description']
                    //     }
                    // });
                    // context.valuesmat = values;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.storageType = [];
                        //context.valuesmat = [];
                    }
                })
                .call()
            },
            
            getBinLoc() {
                let context = this;               
                Api(context, binloc.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.binloc = response.data.data.data.data;
                    // var values = [];                                
                    // response.data.data.forEach(function(value, j) {
                    //     values[j] = {
                    //         'link' : value['material_number'],
                    //         'value': value['material_number'] + '-' + value['short_description']
                    //     }
                    // });
                    // context.valuesmat = values;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.binloc = [];
                        //context.valuesmat = [];
                    }
                })
                .call()
            },
            
            querySearchManuf(queryString, cb) {
                let context = this;
                if (queryString.length > 2) {
                    var links = context.valuesman;
                    var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                    cb(results);
                }
            },
            
            createFilter(queryString) {
                return (link) => {
                return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },

            handleSelectManuf(item, i) {
            },
            
            
            getMaterialSpek() {
                let context = this;        
                var datanya = {};       
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListB4.material_number})).onSuccess(function(response) {    
                    datanya = response.data.data;
                                        
                    //manufacture
                    context.manufPart4 = datanya.part_manufacture_ref
                    var manuf = {
                        manufacture_code: [],
                        manufacture_description: [],
                        mpn: [],
                        manufacture_type: [],
                        manref_description: [],
                        manufacture_note: [],
                        hapus:[],
                    }
                    
                    context.manufPart4.forEach(function(val, m) {
                        manuf.manufacture_code[m] = val['mancode']
                        manuf.manufacture_description[m] = val['manufacture_ref']
                        manuf.mpn[m] = val['material_number_mpn']
                        manuf.manufacture_type[m] = val['type']
                        manuf.manref_description[m] = val['manref_description']
                        manuf.manufacture_note[m] = val['note']
                        manuf.hapus[m] = '1'
                    });
                    
                    context.manufacturePartNumberB4 = manuf;
                    
                    //========Equipment 
                    context.equipmentQty4 = datanya.part_equipment_code
                    var equip = {
                        plant_code: [],
                        equipment_code: [],
                        qty_installed: [],
                        drawing_number: [],
                        hapus: [],
                    }
                    context.equipmentQty4.forEach(function(value, j) {
                        equip.plant_code[j] = value['plant_code']
                        equip.equipment_code[j] = value['equipment_code']
                        equip.qty_installed[j] = value['qty_installed']
                        equip.drawing_number[j] = value['drawing_number']
                        equip.hapus[j] = '1'
                    });
                    
                    context.equipmentQuantityInstallB4 = equip;
                    
                    //Plantcode
                    context.plantList4 = datanya.part_plant
                    var pelan = {
                        plant: [],
                        hapus:[],
                    }
                    
                    context.plantList4.forEach(function(val, m) {
                        pelan.plant[m] = val['plant_code']
                        pelan.hapus[m] = '1'
                    });
                    
                    context.plantLevelB4 = pelan;
                    
                   //========== STorage Location =================================
                   context.sLoc4 = datanya.part_storage_location
                    var sloc = {
                        plant_code: [],
                        storage_location: [],
                        warehouse_number: [],
                        storage_type: [],
                        bin_location: [],
                        hapus:[],
                    }
                    
                    context.sLoc4.forEach(function(val, m) {
                        sloc.plant_code[m] = val['plant_code']
                        sloc.storage_location[m] = val['location_code']
                        sloc.warehouse_number[m] = val['warehouse_no']
                        sloc.storage_type[m] = val['storage_type_code']
                        sloc.bin_location[m] = val['bin_code']
                        sloc.hapus[m] = '1'
                    });
                    
                    context.storageLocation = sloc;
                    
                    //========== Sales =================================
                    
                    context.sale4 = datanya.part_sales
                    var jual = {
                        sls_plant_code:[],
            						sales_org: [],
            						distr_channel: [],
            						division: [],
            						//tax_cat: [],
            						tax_class: [],
            						gen_item_cat_grp: [],
            						account_assign_grp: [],
            						item_cat_grp: [],
            						trans_group: [],
            						loading_group: [],
            						profit_center: [],
            						hapus:[],
                    }
                    
                    context.sale4.forEach(function(val, m) {
                        jual.sls_plant_code[m] = val['plant_code']
                        jual.sales_org[m] = val['sales_org_code']
                        jual.distr_channel[m] = val['distr_channel_code']
                        jual.division[m] = val['division_code']
                        //jual.tax_cat[m] = val['tax_cat_code']
                        
                        jual.tax_class[m] = val['tax_class_code']
                        jual.gen_item_cat_grp[m] = val['gen_item_cat_grp_code']
                        jual.account_assign_grp[m] = val['acc_ass_grp_code']
                        jual.item_cat_grp[m] = val['item_cat_grp_code']
                        jual.trans_group[m] = val['trans_group_code']
                        
                        jual.loading_group[m] = val['loading_group_code']
                        jual.profit_center[m] = val['profit_center_code']
                        
                        jual.hapus[m] = '1'
                    });
                    
                    context.salesB4 = jual;
                    
                    //=========================== MRP =====================================
                            
                    context.mrp4 = datanya.part_mrp
                    var mrptmp = {
                        plant_mrp:[],
                        abc_indicator: [],
                        mrp_type: [],
                        mrp_controller: [],
                        lot_size: [],
                        min: [],
                        max: [],
                        procurement_type: [],
                        special_proc: [],
                        hapus:[],
                    }
                    
                    context.mrp4.forEach(function(val, m) {
                        mrptmp.plant_mrp[m] = val['plant_code']
                        mrptmp.abc_indicator[m] = val['abc_indicator_code']
                        mrptmp.mrp_type[m] = val['mrp_type_code']
                        mrptmp.mrp_controller[m] = val['mrp_controller_code']
                        mrptmp.lot_size[m] = val['lot_size_code']
                        
                        mrptmp.min[m] = val['min']
                        mrptmp.max[m] = val['max']
                        mrptmp.procurement_type[m] = val['procurement_type_code']
                        mrptmp.special_proc[m] = val['special_proc_type_code']
                        
                        
                        mrptmp.hapus[m] = '1'
                    });
                    
                    context.mrpB4 = mrptmp;
                    
                    //=========================== Accounting =====================================
                            
                    context.acct4 = datanya.part_accounting
                    var accttmp = {
                        plant_accounting:[],
                        valuation_class: [],
                        valuation_category: [],
                        valuation_type: [],
                        price_control: [],
                        price_determination: [],
                        standard_price:[],
                        price_unit: [],
                        hapus:[],
                    }
                    
                    context.acct4.forEach(function(val, m) {
                        accttmp.plant_accounting[m] = val['plant_code']
                        accttmp.valuation_class[m] = val['valuation_class_code']
                        accttmp.valuation_category[m] = val['valuation_category_code']
                        accttmp.valuation_type[m] = val['valuation_type_code']
                        accttmp.price_control[m] = val['price_control_code']
                        accttmp.price_determination[m] = val['price_determination']
                        accttmp.standard_price[m] = ''
                        accttmp.price_unit[m] = val['price_unit']
                        accttmp.hapus[m] = '1'
                    });
                    
                    context.accountingB4 = accttmp;
                    
                    //=========================== Purchasing =====================================
                            
                    context.purch4 = datanya.part_purchasing
                    
                    var acctprc = {
                        plant_purchasing: [],
                        purchasing_group: [],
                        order_unit: [],
                        hapus:[],
                    }
                    
                    context.purch4.forEach(function(val, m) {
                        acctprc.plant_purchasing[m] = val['plant_code']
                        acctprc.purchasing_group[m] = val['purchasing_group_code']
                        acctprc.order_unit[m] = val['order_unit']
                        
                        acctprc.hapus[m] = '1'
                    });
                    
                    context.purchasingB4 = acctprc;

                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialNumber = [];
                    }
                })
                .call()
            },
            
            getManrefType() {
                let context = this;    
                Api(context, manrefType.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                        
                    }
                })
                .call()
            },
            
            //==================================== Block For Sales Function ===============================
            getSalesOrganization() {
                let context = this;               
                Api(context, salesOrganization.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.salesOrganization = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.salesOrganization = [];
                    }
                })
                .call()
            },
            getDistributionChannel() {
                let context = this;               
                Api(context, distributionChannel.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.distributionChannel = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.distributionChannel = [];
                    }
                })
                .call()
            },
            getDivision() {
                let context = this;               
                Api(context, division.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.division = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.division = [];
                    }
                })
                .call()
            },
            getTaxClassification() {
                let context = this;               
                Api(context, taxClassification.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.taxClassification = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.taxClassification = [];
                    }
                })
                .call()
            },
            getItemCategoryGroup() {
                let context = this;               
                Api(context, itemCategoryGroup.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.itemCategoryGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.itemCategoryGroup = [];
                    }
                })
                .call()
            },
            getAccountAssignmentGroup() {
                let context = this;               
                Api(context, accountAssignmentGroup.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.accountAssignmentGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.accountAssignmentGroup = [];
                    }
                })
                .call()
            },
            getTransportationGroup() {
                let context = this;               
                Api(context, transportationGroup.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.transportationGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.transportationGroup = [];
                    }
                })
                .call()
            },
            getLoadingGroup() {
                let context = this;               
                Api(context, loadingGroup.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.loadingGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.loadingGroup = [];
                    }
                })
                .call()
            },
            getProfitCenter() {
                let context = this;               
                Api(context, profitCenter.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.profitCenter = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.profitCenter = [];
                    }
                })
                .call()
            },
            //================ Block MRP =========
            getAbcIndicator() {
                let context = this;               
                Api(context, abcIndicator.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.abcIndicator = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.abcIndicator = [];
                    }
                })
                .call()
            },
            getMrpTypes() {
                let context = this;               
                Api(context, mrpTypes.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.mrpTypes = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpTypes = [];
                    }
                })
                .call()
            },
            getMrpController() {
                let context = this;               
                Api(context, mrpController.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.mrpController = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpController = [];
                    }
                })
                .call()
            },
            getLotSize() {
                let context = this;               
                Api(context, lotSize.get()).onSuccess(function(response) {    
                    context.lotSize = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.lotSize = [];
                    }
                })
                .call()
            },
            getProcurementType() {
                let context = this;               
                Api(context, procurementType.get()).onSuccess(function(response) {    
                    context.procurementType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.procurementType = [];
                    }
                })
                .call()
            },
            getSpecialProcurementType() {
                let context = this;               
                Api(context, specialProcurementType.get()).onSuccess(function(response) {    
                    context.specialProcurementType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.specialProcurementType = [];
                    }
                })
                .call()
            },
            
            getValuationClass() {
                let context = this;               
                Api(context, valuationClass.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationClass = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationClass = [];
                    }
                })
                .call()
            },
            getPurchasingGroup() {
                let context = this;               
                Api(context, purchasingGroup.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.purchasingGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.purchasingGroup = [];
                    }
                })
                .call()
            },
            getValuationCategory() {
                let context = this;               
                Api(context, valuationCategory.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationCategory = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationCategory = [];
                    }
                })
                .call()
            },
            getValuationType() {
                let context = this;               
                Api(context, valuationType.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationType = [];
                    }
                })
                .call()
            },
            getPriceControl() {
                let context = this;               
                Api(context, priceControl.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.priceControl = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.priceControl = [];
                    }
                })
                .call()
            },
            
            //======== add manufacture Part Number
            addMpnB4() {
                let context = this;    
                
                if (this.manufacturePartNumberB4.manufacture_code.length == 0) {
                      
                    this.manufacturePartNumberB4.manufacture_code.push('')
                    this.manufacturePartNumberB4.manufacture_description.push('')
                    this.manufacturePartNumberB4.mpn.push('')
                    this.manufacturePartNumberB4.manufacture_type.push('')
                    this.manufacturePartNumberB4.manref_description.push('')
                    this.manufacturePartNumberB4.manufacture_note.push('')
                    this.manufacturePartNumberB4.hapus.push('0')
                    
                } else if (this.manufacturePartNumberB4.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberB4.manufacture_code[this.manufacturePartNumberB4.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberB4.mpn[this.manufacturePartNumberB4.mpn.length-1] != '' &&
                        this.manufacturePartNumberB4.manufacture_type[this.manufacturePartNumberB4.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberB4.manufacture_note[this.manufacturePartNumberB4.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberB4.manufacture_code.push('')
                        this.manufacturePartNumberB4.manufacture_description.push('')
                        this.manufacturePartNumberB4.mpn.push('')
                        this.manufacturePartNumberB4.manufacture_type.push('')
                        this.manufacturePartNumberB4.manref_description.push('')
                        this.manufacturePartNumberB4.manufacture_note.push('')
                        this.manufacturePartNumberB4.hapus.push('0')
                        
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnB4(key) {
                this.manufacturePartNumberB4.manufacture_code.splice(key, 1)
                this.manufacturePartNumberB4.manufacture_description.splice(key, 1)
                this.manufacturePartNumberB4.mpn.splice(key, 1)
                this.manufacturePartNumberB4.manufacture_type.splice(key, 1)
                this.manufacturePartNumberB4.manref_description.splice(key, 1)
                this.manufacturePartNumberB4.manufacture_note.splice(key, 1)
                this.manufacturePartNumberB4.hapus.splice(key, 1)
            },
            
            //======== add Equipment
            addEqiB4() {
                if (this.equipmentQuantityInstallB4.plant_code.length == 0) {
                    this.equipmentQuantityInstallB4.plant_code.push('')
                    this.equipmentQuantityInstallB4.equipment_code.push('')
                    this.equipmentQuantityInstallB4.qty_installed.push('')
                    this.equipmentQuantityInstallB4.drawing_number.push('')
                } else if (this.equipmentQuantityInstallB4.plant_code.length > 0) {
                    if (this.equipmentQuantityInstallB4.plant_code[this.equipmentQuantityInstallB4.plant_code.length-1] != '' &&
                        this.equipmentQuantityInstallB4.equipment_code[this.equipmentQuantityInstallB4.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallB4.qty_installed[this.equipmentQuantityInstallB4.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallB4.drawing_number[this.equipmentQuantityInstallB4.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallB4.plant_code.push('')
                        this.equipmentQuantityInstallB4.equipment_code.push('')
                        this.equipmentQuantityInstallB4.qty_installed.push('')
                        this.equipmentQuantityInstallB4.drawing_number.push('')
                        this.equipmentQuantityInstallB4.hapus.push('0')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiB4(key) {
                this.equipmentQuantityInstallB4.plant_code.splice(key, 1)
                this.equipmentQuantityInstallB4.equipment_code.splice(key, 1)
                this.equipmentQuantityInstallB4.qty_installed.splice(key, 1)
                this.equipmentQuantityInstallB4.drawing_number.splice(key, 1)   
                this.equipmentQuantityInstallB4.hapus.splice(key, 1)
            },
            
            //======== add Plant
            addPlantB4() {
                console.log(this.plantLevelB4)
                if (this.plantLevelB4.plant.length == 0) {
                    this.plantLevelB4.plant.push('')
                } else if (this.plantLevelB4.plant.length > 0) {
                    if (this.plantLevelB4.plant[this.plantLevelB4.plant.length-1] != '') {
                        this.plantLevelB4.plant.push('')
                        this.plantLevelB4.hapus.push('0')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removePlantB4(key) {
                this.plantLevelB4.plant.splice(key, 1)
                this.plantLevelB4.hapus.splice(key, 1)
                
            },
            
            //========================= add SyncLock
            addSlocB4() {
                if (this.storageLocation.plant_code.length == 0) {
                    this.storageLocation.plant_code.push('')
                    this.storageLocation.storage_location.push('')
                    // this.storageLocation.warehouse_number.push('')
                    // this.storageLocation.storage_type.push('')
          					this.storageLocation.bin_location.push('')
          					this.storageLocation.hapus.push('0')
                } else if (this.storageLocation.plant_code.length > 0) {
                    if (this.storageLocation.plant_code[this.storageLocation.plant_code.length-1] != '' &&
                        this.storageLocation.storage_location[this.storageLocation.storage_location.length-1] != '' &&
                        // this.storageLocation.warehouse_number[this.storageLocation.warehouse_number.length-1] != '' &&
                        // this.storageLocation.storage_type[this.storageLocation.storage_type.length-1] != '' &&
                        this.storageLocation.bin_location[this.storageLocation.bin_location.length-1] != '' ) {
                        
                        this.storageLocation.plant_code.push('')
                        this.storageLocation.storage_location.push('')
                        // this.storageLocation.warehouse_number.push('')
                        // this.storageLocation.storage_type.push('')
            						this.storageLocation.bin_location.push('')
                        this.storageLocation.hapus.push('0')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeSlocB4(key) {
                this.storageLocation.plant_code.splice(key, 1)
                this.storageLocation.storage_location.splice(key, 1)
                this.storageLocation.warehouse_number.splice(key, 1)
                this.storageLocation.storage_type.splice(key, 1)   
				this.storageLocation.bin_location.splice(key, 1)   
                this.storageLocation.hapus.splice(key, 1)
            },
            
            //======== add Sales
            addSB4() {
                if (this.salesB4.sales_org.length == 0) {
                    this.salesB4.sales_org.push('')
                    this.salesB4.distr_channel.push('')
                    this.salesB4.division.push('')
                    //this.salesB4.tax_cat.push('')
                    this.salesB4.tax_class.push('')
                    this.salesB4.gen_item_cat_grp.push('')
                    this.salesB4.account_assign_grp.push('')
                    this.salesB4.item_cat_grp.push('')
                    this.salesB4.trans_group.push('')
                    this.salesB4.loading_group.push('')
                    this.salesB4.profit_center.push('')
                    this.salesB4.sls_plant_code.push('')
                    this.salesB4.hapus.push('0')
                } else if (this.salesB4.sales_org.length > 0) {
                    if (this.salesB4.sales_org[this.salesB4.sales_org.length-1] != '' &&
                        this.salesB4.distr_channel[this.salesB4.distr_channel.length-1] != '' &&
                        this.salesB4.division[this.salesB4.division.length-1] != '' &&
                        //this.salesB4.tax_cat[this.salesB4.tax_cat.length-1] != '' &&
                        this.salesB4.tax_class[this.salesB4.tax_class.length-1] != '' &&
                        this.salesB4.gen_item_cat_grp[this.salesB4.gen_item_cat_grp.length-1] != '' &&
                        this.salesB4.account_assign_grp[this.salesB4.account_assign_grp.length-1] != '' &&
                        this.salesB4.item_cat_grp[this.salesB4.item_cat_grp.length-1] != '' &&
                        this.salesB4.trans_group[this.salesB4.trans_group.length-1] != '' &&
                        this.salesB4.loading_group[this.salesB4.loading_group.length-1] != '' &&
                        this.salesB4.profit_center[this.salesB4.trans_group.length-1] != '') {
                        
                        this.salesB4.sales_org.push('')
                        this.salesB4.distr_channel.push('')
                        this.salesB4.division.push('')
                        //this.salesB4.tax_cat.push('')
                        this.salesB4.tax_class.push('')
                        this.salesB4.gen_item_cat_grp.push('')
                        this.salesB4.account_assign_grp.push('')
                        this.salesB4.item_cat_grp.push('')
                        this.salesB4.trans_group.push('')
                        this.salesB4.loading_group.push('')
                        this.salesB4.profit_center.push('')
                        this.salesB4.sls_plant_code.push('')
                        this.salesB4.hapus.push('0')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }                
            },
            removeSB4(key) {
                this.salesB4.sales_org.splice(key, 1)
                this.salesB4.distr_channel.splice(key, 1)
                this.salesB4.division.splice(key, 1)
                //this.salesB4.tax_cat.splice(key, 1)
                this.salesB4.tax_class.splice(key, 1)
                this.salesB4.gen_item_cat_grp.splice(key, 1)
                this.salesB4.account_assign_grp.splice(key, 1)
                this.salesB4.item_cat_grp.splice(key, 1)
                this.salesB4.trans_group.splice(key, 1)
                this.salesB4.loading_group.splice(key, 1)
                this.salesB4.profit_center.splice(key, 1)
                this.salesB4.sls_plant_code.splice(key, 1)
                this.salesB4.hapus.splice(key, 1)
            },
            
            addMrpB4() {
                if (this.mrpB4.abc_indicator.length == 0) {
                    this.mrpB4.abc_indicator.push('')
                    this.mrpB4.mrp_type.push('')
                    this.mrpB4.mrp_controller.push('')
                    this.mrpB4.lot_size.push('')
                    this.mrpB4.min.push('')
                    this.mrpB4.max.push('')
                    this.mrpB4.procurement_type.push('')
                    this.mrpB4.special_proc.push('')
                    this.mrpB4.plant_mrp.push('')
                    this.mrpB4.hapus.push('0')
                } else if (this.mrpB4.abc_indicator.length > 0) {
                    if (this.mrpB4.abc_indicator[this.mrpB4.abc_indicator.length-1] != '' &&
                        this.mrpB4.mrp_type[this.mrpB4.mrp_type.length-1] != '' &&
                        this.mrpB4.mrp_controller[this.mrpB4.mrp_controller.length-1] != '' &&
                        this.mrpB4.lot_size[this.mrpB4.lot_size.length-1] != '' &&
                        this.mrpB4.min[this.mrpB4.min.length-1] != '' &&
                        this.mrpB4.max[this.mrpB4.max.length-1] != '' &&
                        this.mrpB4.procurement_type[this.mrpB4.procurement_type.length-1] != '' &&
                        this.mrpB4.special_proc[this.mrpB4.special_proc.length-1] != '') {
                        
                        this.mrpB4.abc_indicator.push('')
                        this.mrpB4.mrp_type.push('')
                        this.mrpB4.mrp_controller.push('')
                        this.mrpB4.lot_size.push('')
                        this.mrpB4.min.push('')
                        this.mrpB4.max.push('')
                        this.mrpB4.procurement_type.push('')
                        this.mrpB4.special_proc.push('')
                        this.mrpB4.plant_mrp.push('')
                        this.mrpB4.hapus.push('0')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMrpB4(key) {
                this.mrpB4.abc_indicator.splice(key, 1)
                this.mrpB4.mrp_type.splice(key, 1)
                this.mrpB4.mrp_controller.splice(key, 1)
                this.mrpB4.lot_size.splice(key, 1)
                this.mrpB4.min.splice(key, 1)
                this.mrpB4.max.splice(key, 1)
                this.mrpB4.procurement_type.splice(key, 1)
                this.mrpB4.special_proc.splice(key, 1)
                this.mrpB4.plant_mrp.push('')
                this.mrpB4.hapus.splice(key, 1)
            },
            addAB2() {
                if (this.accountingB4.valuation_class.length == 0) {
                    this.accountingB4.valuation_class.push('')
                    this.accountingB4.valuation_category.push('')
                    this.accountingB4.valuation_type.push('')
                    this.accountingB4.price_control.push('')
                    this.accountingB4.price_determination.push('')
                    this.accountingB4.price_unit.push('')
                    this.accountingB4.plant_accounting.push('')
                    this.accountingB4.hapus.push('0')
                } else if (this.accountingB4.valuation_class.length > 0) {
                    if (this.accountingB4.valuation_class[this.accountingB4.valuation_class.length-1] != '' &&
                        this.accountingB4.valuation_category[this.accountingB4.valuation_category.length-1] != '' &&
                        this.accountingB4.valuation_type[this.accountingB4.valuation_type.length-1] != '' &&
                        this.accountingB4.price_control[this.accountingB4.price_control.length-1] != '' &&
                        this.accountingB4.price_determination[this.accountingB4.price_determination.length-1] != '' &&
                        this.accountingB4.price_unit[this.accountingB4.price_unit.length-1] != '') {
                        
                        this.accountingB4.valuation_class.push('')
                        this.accountingB4.valuation_category.push('')
                        this.accountingB4.valuation_type.push('')
                        this.accountingB4.price_control.push('')
                        this.accountingB4.price_determination.push('')
                        this.accountingB4.price_unit.push('')
                        this.accountingB4.plant_accounting.push('')
                        this.accountingB4.hapus.push('0')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeAB2(key) {
                this.accountingB4.valuation_class.splice(key, 1)
                this.accountingB4.valuation_category.splice(key, 1)
                this.accountingB4.valuation_type.splice(key, 1)
                this.accountingB4.price_control.splice(key, 1)
                this.accountingB4.price_determination.splice(key, 1)
                this.accountingB4.price_unit.splice(key, 1)
                this.accountingB4.plant_accounting.splice(key, 1)
                this.accountingB4.hapus.splice(key, 1)
            },
            addPurchB4() {
                if (this.purchasingB4.plant_purchasing.length == 0) {
                    this.purchasingB4.plant_purchasing.push('')
                    this.purchasingB4.purchasing_group.push('')
                    this.purchasingB4.order_unit.push('')
                    this.purchasingB4.hapus.push('0')
                } else if (this.purchasingB4.plant_purchasing.length > 0) {
                    if (this.purchasingB4.plant_purchasing[this.purchasingB4.plant_purchasing.length-1] != '' &&
                        this.purchasingB4.purchasing_group[this.purchasingB4.purchasing_group.length-1] != '' &&
                        this.purchasingB4.order_unit[this.purchasingB4.order_unit.length-1] != '') {
                        
                        this.purchasingB4.plant_purchasing.push('')
                        this.purchasingB4.purchasing_group.push('')
                        this.purchasingB4.order_unit.push('')
                        this.purchasingB4.hapus.push('0')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removePurchB4(key) {
                this.purchasingB4.plant_purchasing.splice(key, 1)
                this.purchasingB4.purchasing_group.splice(key, 1)
                this.purchasingB4.order_unit.splice(key, 1)
                this.purchasingB4.hapus.splice(key, 1)
            },
            
            
            //================================= Save ==================================
            saveB2(save) {
                let context = this;
                let formData = new FormData();

                if (context.inputDraftListB4.material_number != '' &&
                    // context.inputDraftListB2.item_type_code != '' &&
                    // context.inputDraftListB2.prc_nsc != '' &&
                    // context.inputDraftListB2.uom_code != '' &&
                    // context.inputDraftListB2.mtype_code != '' &&
                    // context.inputDraftListB2.prc_catalog_type_code != '' &&
                    // context.inputDraftListB2.mgroup_code != '' &&
                    // context.inputDraftListB2.fab_non_fab != '' &&
                    // context.inputDraftListB2.prc_sos_header != '' &&
                    // context.inputDraftListB2.bom_non_bom != '' &&
                    // context.inputDraftListB2.prc_po_text_memo != '' &&
                    context.inputDraftListB4.note != '') {

                    formData.append('mcr_code', context.mcr_code)
                    // formData.append('prc_inc', context.inputDraftListB2.prc_inc)
                    // formData.append('prc_item_type_code', context.inputDraftListB2.item_type_code)
                    // formData.append('prc_nsc', context.inputDraftListB2.prc_nsc)
                    // formData.append('prc_uom_code', context.inputDraftListB2.uom_code)
                    // formData.append('prc_mtype_code', context.inputDraftListB2.mtype_code)
                    // formData.append('prc_catalog_type_code', context.inputDraftListB2.prc_catalog_type_code)
                    // formData.append('prc_mgroup_code', context.inputDraftListB2.prc_mgroup_code)
                    // formData.append('prc_fab_non_fab', context.inputDraftListB2.fab_non_fab)
                    // formData.append('prc_sos_header', context.inputDraftListB2.prc_sos_header)
                    // formData.append('prc_bom_non_bom', context.inputDraftListB2.bom_non_bom)
                    // formData.append('prc_po_text_memo', context.inputDraftListB2.prc_po_text_memo)
                    formData.append('note', context.inputDraftListB4.note)

                    if (context.manufacturePartNumberB4.manufacture_code.length) {
                      for (var i=0; i<context.manufacturePartNumberB4.manufacture_code.length; i++) {
                          // if (context.manufacturePartNumberB4.manufacture_code[i] != '' &&
                          //     context.manufacturePartNumberB4.mpn[i] != '' &&
                          //     context.manufacturePartNumberB4.manufacture_type[i] != '' &&
                          //     context.manufacturePartNumberB4.manufacture_note[i] != '') {
                             
                              formData.append('prc_manufacture_code['+i+']', context.manufacturePartNumberB4.manufacture_code[i])
                              formData.append('prc_mpn['+i+']', context.manufacturePartNumberB4.mpn[i])
                              formData.append('prc_manufacture_type['+i+']', context.manufacturePartNumberB4.manufacture_type[i])
                              formData.append('prc_manufacture_note['+i+']', context.manufacturePartNumberB4.manufacture_note[i])
                          // } 
                         
                      }
                    } else {
                      formData.append('prc_manufacture_code', '')
                      formData.append('prc_mpn', '')
                      formData.append('prc_manufacture_type', '')
                      formData.append('prc_manufacture_note', '')
                    }
                        
                    if (context.plantLevelB4.plant.length) {
                      for (var i=0; i<context.plantLevelB4.plant.length; i++) {
                          // if (context.plantLevelB4.plant[i] != '') {
                              formData.append('prc_plant_code['+i+']', context.plantLevelB4.plant[i])
                          // } else {
                          //     context.$notify({
                          //         message: 'Please complete data!',                  
                          //         type: 'danger'
                          //     });
                          //     return false;
                          // }
                      }
                    } else {
                      formData.append('prc_plant_code', '')
                    }
                    
                    if (context.storageLocation.storage_location.length) {
                      for (var i=0; i<context.storageLocation.storage_location.length; i++) {
                          if (context.storageLocation.storage_location[i] != '' &&
                              // context.storageLocation.warehouse_number[i] != '' &&
                              // context.storageLocation.storage_type[i] != '' &&
                              context.storageLocation.bin_location[i] != '') {
                             
                              formData.append('prc_plant_sloc['+i+']', context.storageLocation.plant_code[i])
                              formData.append('prc_storage_location['+i+']', context.storageLocation.storage_location[i])
                              // formData.append('prc_warehouse_no['+i+']', context.storageLocation.warehouse_number[i])
                              // formData.append('prc_storage_type_code['+i+']', context.storageLocation.storage_type[i])
                              formData.append('prc_bin_code['+i+']', context.storageLocation.bin_location[i])
                          } 
                         
                      }
                    } else {
                      formData.append('prc_plant_sloc', '')
                      formData.append('prc_storage_location', '')
                      // formData.append('prc_warehouse_no', '')
                      // formData.append('prc_storage_type_code', '')
                      formData.append('prc_bin_code', '')
                    }
                        
                    if (context.salesB4.sales_org.length) {
                      for (var i=0; i<context.salesB4.sales_org.length; i++) {
                          // if (context.salesB4.sales_org[i] != '' &&
                          //     context.salesB4.distr_channel[i] != '' &&
                          //     context.salesB4.division[i] != '' &&
                          //     context.salesB4.tax_class[i] != '' &&
                          //     context.salesB4.tax_class[i] != '' &&
                          //     context.salesB4.gen_item_cat_grp[i] != '' &&
                          //     context.salesB4.account_assign_grp[i] != '' &&
                          //     context.salesB4.item_cat_grp[i] != '' &&
                          //     context.salesB4.trans_group[i] != '' &&
                          //     context.salesB4.loading_group[i] != '' &&
                          //     context.salesB4.profit_center[i] != '') {
                              
                              formData.append('prc_plant_sales['+i+']', context.salesB4.sls_plant_code[i])
                              formData.append('prc_sales_org_code['+i+']', context.salesB4.sales_org[i])
                              formData.append('prc_distr_channel_code['+i+']', context.salesB4.distr_channel[i])
                              formData.append('prc_division_code['+i+']', context.salesB4.division[i])
                              //formData.append('prc_tax_cat_code['+i+']', context.salesB4.tax_class[i])
                              formData.append('prc_tax_class_code['+i+']', context.salesB4.tax_class[i])
                              formData.append('prc_gen_item_cat_grp_code['+i+']', context.salesB4.gen_item_cat_grp[i])
                              formData.append('prc_acc_ass_grp_code['+i+']', context.salesB4.account_assign_grp[i])
                              formData.append('prc_item_cat_grp_code['+i+']', context.salesB4.item_cat_grp[i])
                              formData.append('prc_trans_group_code['+i+']', context.salesB4.trans_group[i])
                              formData.append('prc_loading_group_code['+i+']', context.salesB4.loading_group[i])
                              formData.append('prc_profit_center_code['+i+']', context.salesB4.profit_center[i])
                          // }
                      }
                    } else {
                      formData.append('prc_plant_sales', '')
                      formData.append('prc_sales_org_code', '')
                      formData.append('prc_distr_channel_code', '')
                      formData.append('prc_division_code', '')
                      //formData.append('prc_tax_cat_code', '')
                      formData.append('prc_tax_class_code', '')
                      formData.append('prc_gen_item_cat_grp_code', '')
                      formData.append('prc_acc_ass_grp_code', '')
                      formData.append('prc_trans_group_code', '')
                      formData.append('prc_loading_group_code', '')
                      formData.append('prc_profit_center_code', '')
                    }
                        
                    if (context.mrpB4.abc_indicator.length) {
                      for (var i=0; i<context.mrpB4.abc_indicator.length; i++) {
                          if (context.mrpB4.abc_indicator[i] != '' &&
                              context.mrpB4.mrp_type[i] != '' &&
                              // context.mrpB4.mrp_controller[i] != '' &&
                              context.mrpB4.lot_size[i] != '' &&
                              context.mrpB4.min[i] != '' &&
                              context.mrpB4.max[i] != '' &&
                              context.mrpB4.procurement_type[i] != '' 
                              // && context.mrpB4.special_proc[i] != ''
                            ) {

                              formData.append('prc_plant_mrp['+i+']', context.mrpB4.plant_mrp[i])
                              formData.append('prc_abc_indicator_code['+i+']', context.mrpB4.abc_indicator[i])
                              formData.append('prc_mrp_type_code['+i+']', context.mrpB4.mrp_type[i])
                              formData.append('prc_mrp_controller_code['+i+']', context.mrpB4.mrp_controller[i])
                              formData.append('prc_lot_size_code['+i+']', context.mrpB4.lot_size[i])
                              formData.append('prc_min_stock['+i+']', context.mrpB4.min[i])
                              formData.append('prc_max_stock['+i+']', context.mrpB4.max[i])
                              formData.append('prc_procurement_type_code['+i+']', context.mrpB4.procurement_type[i])
                              formData.append('prc_special_proc_type_code['+i+']', context.mrpB4.special_proc[i])
                          } 
                      }
                    } else {
                      formData.append('prc_plant_mrp', '')
                      formData.append('prc_abc_indicator_code', '')
                      formData.append('prc_mrp_type_code', '')
                      formData.append('prc_mrp_controller_code', '')
                      formData.append('prc_lot_size_code', '')
                      formData.append('prc_min_stock', '')
                      formData.append('prc_max_stock', '')
                      formData.append('prc_procurement_type_code', '')
                      formData.append('prc_special_proc_type_code', '')
                    }
                        
                    if (context.accountingB4.valuation_class.length) {
                      for (var i=0; i<context.accountingB4.valuation_class.length; i++) {
                          // if (context.accountingB4.valuation_class[i] != '' &&
                          //     context.accountingB4.valuation_category[i] != '' &&
                          //     context.accountingB4.valuation_type[i] != '' &&
                          //     context.accountingB4.price_control[i] != '' &&
                          //     context.accountingB4.price_determination[i] != '' &&
                          //     context.accountingB4.price_unit[i] != '') {

                              formData.append('prc_plant_accounting['+i+']', context.accountingB4.plant_accounting[i])
                              formData.append('prc_valuation_class_code['+i+']', context.accountingB4.valuation_class[i])
                              formData.append('prc_valuation_category_code['+i+']', context.accountingB4.valuation_category[i])
                              formData.append('prc_valuation_type_code['+i+']', context.accountingB4.valuation_type[i])
                              formData.append('prc_price_control_code['+i+']', context.accountingB4.price_control[i])
                              formData.append('prc_price_determination_code['+i+']', context.accountingB4.price_determination[i])
                              formData.append('prc_price_unit['+i+']', context.accountingB4.price_unit[i])
                          // } else {
                          //     context.$notify({
                          //         message: 'Please complete data!',                  
                          //         type: 'danger'
                          //     });
                          //     return false;
                          // }
                      }
                    } else {
                      formData.append('prc_plant_accounting', '')
                      formData.append('prc_valuation_class_code', '')
                      formData.append('prc_valuation_category_code', '')
                      formData.append('prc_valuation_type_code', '')
                      formData.append('prc_price_control_code', '')
                      formData.append('prc_price_determination_code', '')
                      formData.append('prc_price_unit', '')
                    }
                        
                    
                
                    if (context.equipmentQuantityInstallB4.plant_code.length) {
                      for (var i=0; i<context.equipmentQuantityInstallB4.plant_code.length; i++) {
                          // if (context.equipmentQuantityInstallB4.plant_code[i] != '' &&
                          //     context.equipmentQuantityInstallB4.equipment_code[i] != '' &&
                          //     context.equipmentQuantityInstallB4.qty_installed[i] != '' &&
                          //     context.equipmentQuantityInstallB4.drawing_number[i] != '') {

                              formData.append('prc_plant_equipment['+i+']', context.equipmentQuantityInstallB4.plant_code[i])
                              formData.append('prc_equipment_code['+i+']', context.equipmentQuantityInstallB4.equipment_code[i])
                              formData.append('prc_qty_installed['+i+']', context.equipmentQuantityInstallB4.qty_installed[i])
                              formData.append('prc_drawing_number['+i+']', context.equipmentQuantityInstallB4.drawing_number[i])
                          // }
                      }
                    } else {
                      formData.append('prc_plant_equipment', '')
                      formData.append('prc_equipment_code', '')
                      formData.append('prc_qty_installed', '')
                      formData.append('prc_drawing_number', '')
                    }

                    if (context.purchasingB4.plant_purchasing.length) {
                      for (var i=0; i<context.purchasingB4.plant_purchasing.length; i++) {
                          if (context.purchasingB4.plant_purchasing[i] != '' &&
                              context.purchasingB4.purchasing_group[i] != '' 
                              // && context.purchasingB4.order_unit[i] != '' 
                            ) {

                              formData.append('prc_plant_purchasing['+i+']', context.purchasingB4.plant_purchasing[i])
                              formData.append('prc_purchasing_group_code['+i+']', context.purchasingB4.purchasing_group[i])
                              formData.append('prc_order_unit['+i+']', context.purchasingB4.order_unit[i])
                          } 
                      }
                    } else {
                      formData.append('prc_plant_purchasing', '')
                      formData.append('prc_purchasing_group_code', '')
                      formData.append('prc_order_unit', '')
                    }
                        
                    // if (context.attachmentDocumentB2.file_attachment_code.length) {
                    //   for (var i=0; i<context.attachmentDocumentB2.file_attachment_code.length; i++) {
                    //       if (context.attachmentDocumentB2.file_attachment_code.length > 0 &&
                    //           context.attachmentDocumentB2.file_attachment_code[context.attachmentDocumentB2.file_attachment_code.length-1] != '') {
                    //           formData.append('file_attachment_code['+i+']', context.attachmentDocumentB2.file_attachment_code[i])
                    //       } 
                    //   }
                    // } else {
                    //   formData.append('file_attachment_code', '')
                    // }
                        
                    // if (context.keywordB2.keyword.length) {
                    //   for (var i=0; i<context.keywordB2.keyword.length; i++) {
                    //       if (context.keywordB2.keyword[i] != '') {
                    //           formData.append('keyword['+i+']', context.keywordB2.keyword[i])
                    //       } else {
                    //           context.$notify({
                    //               message: 'Please complete data!',                  
                    //               type: 'danger'
                    //           });
                    //           return false;
                    //       }
                    //   }
                    // } else {
                    //   formData.append('keyword', '')
                    // }

                    // if (context.charValueB2.cin_code.length) {
                    //   for (var i=0; i<context.charValueB2.cin_code.length; i++) {
                    //       if (context.charValueB2.cin_code[i] != '') {
                    //           formData.append('cin_code['+i+']', context.charValueB2.cin_code[i])
                    //           formData.append('characteristic_value['+i+']', (context.charValueB2.value[i] != undefined) ? context.charValueB2.value[i] : '')
                    //           formData.append('view['+i+']', (context.charValueB2.view[i] == true ? 'checked' : ''))
                    //       } else {
                    //           context.$notify({
                    //               message: 'Please complete data!',                  
                    //               type: 'danger'
                    //           });
                    //           return false;
                    //       }
                    //   }
                    // } else {
                    //   formData.append('cin_code', '')
                    //   formData.append('characteristic_value', '')
                    //   formData.append('view', '')
                    // }

                    for (var pair of formData.entries()) {
                        console.log(pair[0]+ ', ' + pair[1]); 
                    }
                    // console.log(context.inputDraftListB4.id)
                    Api(context, draftList.process(formData, context.inputDraftListB4.id))
                    .onSuccess(function(response) {    
                        //context.get();
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success'
                        });
                        // context.form.b2.show = false;

                        context.$router.push({ path: '/material/approval-page/'+context.mcr_code+'/'+context.token});
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                    return false;
                }                
            },
            
            
            //============================Blok A3================================
            getMcrDetilA1(mcr_item_code) {
                let context = this;
                Api(context, draftList.getMcrItemMpn(mcr_item_code)).onSuccess(function (response) {
                    context.mpnA3 = response.data.data
                    // for (var i = 0; i < response.data.data.length; i++) {
                        // context.manufacturePartNumberA1.manufacture_code[i] = response.data.data[i].manufacture_code;
                        // context.manufacturePartNumberA1.mpn[i] = response.data.data[i].mpn;
                        // context.manufacturePartNumberA1.manufacture_type[i] = response.data.data[i].manufacture_type;
                        // context.manufacturePartNumberA1.manufacture_note[i] = response.data.data[i].manufacture_note;
                    //}
                }).onError(function (error) {
                if (error.response.status == 404) {
                    // context.manufacturePartNumberA1.manufacture_code = [];
                    // context.manufacturePartNumberA1.mpn = [];
                    // context.manufacturePartNumberA1.manufacture_type = [];
                    // context.manufacturePartNumberA1.manufacture_note = [];
                }
                }).call()
                
                Api(context, draftList.getMcrItemEquipment(mcr_item_code)).onSuccess(function (response) {
                    context.equipA3 = response.data.data
                    // for (var i = 0; i < response.data.data.length; i++) {
                    //     context.equipmentQuantityInstallA1.equipment_code[i] = response.data.data[i].equipment_code;
                    //     context.equipmentQuantityInstallA1.qty_installed[i] = response.data.data[i].qty_installed;
                    //     context.equipmentQuantityInstallA1.drawing_number[i] = response.data.data[i].drawing_number;
          
                    //     //B2
                    //     context.equipmentQuantityInstallB4.equipment_code[i] = response.data.data[i].equipment_code;
                    //     context.equipmentQuantityInstallB4.qty_installed[i] = response.data.data[i].qty_installed;
                    //     context.equipmentQuantityInstallB4.drawing_number[i] = response.data.data[i].drawing_number;
                    // }
                }).onError(function (error) {
                    // if (error.response.status == 404) {
                    //     context.equipmentQuantityInstallA1.equipment_code = [];
                    //     context.equipmentQuantityInstallA1.qty_installed = [];
                    //     context.equipmentQuantityInstallA1.drawing_number = [];
                    //     context.equipmentQuantityInstallB4.equipment_code[i] = [];
                    //     context.equipmentQuantityInstallB4.qty_installed[i] = [];
                    //     context.equipmentQuantityInstallB4.drawing_number[i] = [];
                    // }
                }).call()
                
            },
            sayhay(){
                alert('hai')
            }
            
            
            
            
        }   
    };
</script>

<style></style>
